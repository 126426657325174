<template>
  <div class="homepage-help-center">
    <header class="block-header">
      <div class="block-title">
        <i class="ph-info text-primary-100" style="font-size: 28px" />
        <!-- <img src="../assets/Info.svg"> -->
        <p>幫助中心</p>
      </div>
    </header>
    <main class="btn-list">
      <router-link :to="{name: 'FAQ'}">
        <div class="btn">
          <img src="../assets/help.svg">
          常見問題
        </div>
      </router-link>
      <router-link :to="{name: 'HandbookList'}">
        <div class="btn">
          <img src="../assets/book.svg">
          教學手冊
        </div>
      </router-link>
      <a :href="bobLineAt" target="_blank" rel="noopener noreferrer">
        <div class="btn">
          <img src="../assets/chat.svg">
          與我們聯絡
        </div>
      </a>
    </main>
  </div>
</template>

<script>
import { bobLineAt } from '@/config/line'
export default {
  name: 'HomePageHelpCenter',
  data: () => ({
    bobLineAt,
  }),
}
</script>

<style lang="postcss" scoped>
.block-header {
  @apply flex ;
  @apply text-lg leading-[21.6px] tracking-[1px] font-medium text-primary-100 justify-between;
}

.btn-list {
  @apply flex gap-[20px] mt-[12px];
}

.block-title {
  @apply flex items-center text-primary-100 gap-[12px] font-medium;
}

.btn {
  @apply bg-white p-[12px] flex items-center gap-[6px];
  @apply cursor-pointer rounded-sm;
  @apply hover:text-primary-100;
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}
</style>
