<template>
  <div class="line-notify-quota">
    <p>方案合約效期：{{ contractExpired }}</p>
    <p>目前 Line 訊息使用：{{ quota.usage }} / 本月Line訊息總額度：{{ quota.limit }} </p>
    <ProgressBar :percentage="quotaPercentege" />
    <LineNotifyQuotaWarning
      v-if="showQuotaWarning"
      @close="showQuotaWarning = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import ProgressBar from '@/components/Progress/ProgressBar.vue'
import { useLineNotify } from '@/use/useLineNotify'
import LineNotifyQuotaWarning from '@/components/Dialog/LineNotifyQuotaWarning.vue'
import store from '@/store'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'LineNotifyQuota',
  components: { ProgressBar, LineNotifyQuotaWarning },
  setup (props) {
    const { quota, quotaPercentege, checkLineQuota, showQuotaWarning, openWarningModal } = useLineNotify()
    const contractExpired = computed(() => {
      const endDate = get(store.state, 'shop.shopConfig.ContractPlan.Contract.end')
      return endDate ? formatDate(endDate, 'YYYY/MM/DD HH:mm') : '-'
  })

    onMounted(async () => {
      await checkLineQuota()
      openWarningModal()
    })
    return { quota, quotaPercentege, showQuotaWarning, contractExpired }
  },
})
</script>

<style lang="postcss" scoped>

</style>
